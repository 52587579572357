import { io } from "socket.io-client";

/**
 * Creates Unit and Question docs in database
 * from the imported data.
 * @param {Array} parsedData
 * @returns Promise
 */
export async function createUnitsAndQuestionsFromImport(db, parsedData) {
  try {
    if (!Array.isArray(parsedData)) {
      throw Error(`parsedData must be an array. Received ${parsedData}.`);
    }

    const questionRefs = await createAllQuestions(parsedData);

    console.log(`All question refs: `);
    console.log(questionRefs);

    for (let i = 0; i < parsedData?.length; i++) {
      const unitName = parsedData[i]?.unit;

      // Check if a unit exists with this name
      const existingUnitSnapshot = await getUnitByUnitName(unitName);

      let unitRef;

      if (false) {
        unitRef = existingUnitSnapshot?.docs[0]?.ref;
      } else {
        unitRef = await db.collection("catalog/DEFAULT/units")?.add({
          name: parsedData[i].unit,
          desc: parsedData[i].unitdesc,
        });
      }

      console.log("unitRef", unitRef);

      if (unitRef) {
        console.log(`Question Index: ${i}`);
        console.log(`Question: ${parsedData[i]?.question}`);
        const questionRef = await unitRef?.collection("questions")?.add({
          ref: questionRefs[i],
          index: i,
          xp: Number(parsedData[i]?.xp) || 0,
        });
        console.log("questionRef", questionRef);
      }
    }

    return Promise.resolve(true);
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Returns a unit doc from `catalog/DEFAULT/units`
 * by unitName
 * @param {String} unitName
 * @returns Promise
 */
export async function getUnitByUnitName(db, unitName) {
  try {
    if (!unitName) {
      throw Error(`Invalid input param value unitName: ${unitName}`);
    }

    return db
      .collection("catalog/DEFAULT/units")
      ?.where("name", "==", unitName)
      ?.get();
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Returns a unit doc from within a courseReference
 * by unitName
 * @param {DocumentReference} courseRef
 * @param {String} unitName
 * @returns Promise
 */
export async function getUnitWithinCourse(courseRef, unitName) {
  try {
    if (!unitName) {
      throw Error(`Invalid input param value unitName: ${unitName}`);
    }

    // TODO: Validate: courseRef

    return courseRef.collection("units")?.where("name", "==", unitName)?.get();
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Returns a Course doc from `catalog`
 * by courseName
 * @param {String} courseName
 * @returns Promise
 */
export async function getCourseByCourseName(db, courseName) {
  try {
    if (!courseName) {
      throw Error(`Invalid input param value courseName: ${courseName}`);
    }

    return db?.collection("catalog")?.where("name", "==", courseName)?.get();
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Returns a question doc from `/topics`
 * by question string
 * @param {String} questionString
 * @returns Promise
 */
export async function getQuestionByQuestionString(db, questionString) {
  try {
    if (!questionString) {
      throw Error(
        `Invalid input param value questionString: ${questionString}`
      );
    }

    return db
      .collection("/topics")
      .where("question", "==", questionString)
      .get();
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Creates/Updates question on db
 * @param {Object} question
 * @returns Promise
 */
export async function addQuestionToDb(db, question) {
  try {
    if (!question?.question) {
      throw Error(
        `question field missing from question input object. Given question: ${question}`
      );
    }

    if (!question?.QTYPE) {
      // Default question type is 'default'
      question.QTYPE = "default";
    }

    question.QTYPE = question?.QTYPE?.toLowerCase();

    const _ACCEPTED_QTYPES = [
      "default",
      "read_only",
      "profiling",
      "routing_type",
      "mcq",
      "text_input",
    ];
    
    const VALID_MCQ_OPTIONS = ["a", "b", "c", "d"];

    if (!_ACCEPTED_QTYPES.includes(question.QTYPE)) {
      throw new Error(
        `question type: QTYPE value is invalid. Allowed values: ${_ACCEPTED_QTYPES}. Given: ${question.QTYPE}`
      );
    }

    if (question?.QTYPE?.toLowerCase() === "default") {
      // Make sure the prompt is not missing
      if (!question?.prompt || question?.prompt?.length <= 0) {
        throw Error(
          `Prompt is required for DEFAULT question type. Given prompt: ${question?.prompt}`
        );
      }
    }

    // For mcq type questions: options and correctOption fields are required.
    if (question.QTYPE === "mcq") {
      if (isEmpty(question?.optionA) || isEmpty(question?.optionB) || isEmpty(question?.optionC) || isEmpty(question?.optionD)) {
        throw new Error(`columns optionA, optionB, optionC, and, optionD are required for QTYPE: ${question.QTYPE}`);
      }

      

      if (isEmpty(question?.correctOption) || typeof question?.correctOption !== "string" || VALID_MCQ_OPTIONS.indexOf(question?.correctOption?.toLowerCase()) === -1) {
        throw new Error(`For QTYPE: ${question.QTYPE}, correctOption field is required. 
          Expecting correctOption field to be one of ${VALID_MCQ_OPTIONS.join(", ")}, but got correctOption: ${question?.correctOption}`);
      }

      question["options"] = [question.optionA, question.optionB, question.optionC, question.optionD];
    }

    if (question?.skippable) {
      if (question?.skippable.toLowerCase() === "false") {
        question.skippable = false;
      } else {
        question.skippable = true;
      }
    } else {
      // All questions are skippable by default
      question.skippable = true;
    }

    if (question?.videoAnswerRequired) {
      if (question?.videoAnswerRequired.toLowerCase() === "false") {
        question.videoAnswerRequired = false;
      } else {
        question.videoAnswerRequired = true;
      }
    } else {
      question.videoAnswerRequired = false;
    }

    if (question?.maxSubmissions) {
      let _maxSubmissions = Number(question.maxSubmissions);
      if (Number.isNaN(Number(_maxSubmissions))) {
        question.maxSubmissions = 3;
      }
    } else {
      question.maxSubmissions = 3; // Default max submissions
    }

    if (question?.maxVideoAnswerLengthInSeconds) {
      let _maxVideoAnswerLength = Number(
        question.maxVideoAnswerLengthInSeconds
      );
      if (Number.isNaN(_maxVideoAnswerLength)) {
        question.maxVideoAnswerLengthInSeconds = 90;
      }
    } else {
      question.maxVideoAnswerLengthInSeconds = 90;
    }

    if (question?.difficulty) {
      let _difficulty = Number(question.difficulty);
      if (Number.isNaN(_difficulty) || _difficulty < 0) {
        question.difficulty = 0;
      } else {
        question.difficulty = _difficulty;
      }
    } else {
      question.difficulty = 0;
    }

    const questionData = {
      notes: question?.QNOTES ? question?.QNOTES : "",
      prompt: question?.prompt ? question?.prompt : "",
      question: question.question,
      status: question?.QSTATUS,
      type: question?.QTYPE,
      skippable: question.skippable,
      maxSubmissions: Number(question.maxSubmissions),
      maxVideoAnswerLengthInSeconds:
        Number(question.maxVideoAnswerLengthInSeconds) || 90,
      videoAnswerRequired: question.videoAnswerRequired,
      difficulty: Number(question.difficulty),
      // routing_type: question.routing_type,
    };

    if (question.QTYPE === "mcq") {
      questionData["options"] = question["options"];
      let correctOptionIndex = VALID_MCQ_OPTIONS.indexOf(question?.correctOption?.toLowerCase())
      questionData["correctOption"] = question["options"][correctOptionIndex];
    }

    const existingQuestionSnapshot = await getQuestionByQuestionString(
      db,
      question.question
    );

    if (false) {
      const existingQuestionRef = existingQuestionSnapshot.docs[0].ref;
      return Promise.resolve(existingQuestionRef);
    } else {
      const questionDocRef = await db.collection("/topics").add(questionData);
      return Promise.resolve(questionDocRef);
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

/**
 *
 * @param {Array} parsedData
 * @returns Promise
 */
export async function createAllQuestions(db, parsedData) {
  try {
    if (Array.isArray(parsedData) !== true) {
      throw Error(
        `Input param parsedData invalid. Expecting an array. Got ${parsedData}`
      );
    }
    const questionRefs = [];
    for (const question of parsedData) {
      console.log("questionquestionquestion", question);
      const questionDocRef = await addQuestionToDb(db, question);
      questionRefs.push(questionDocRef);
    }
    return Promise.resolve(questionRefs);
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Takes in raw csv file string data and parses it to an
 * array of json objects.
 * @param {String} csvString
 * @param {Function} readString
 * @returns Promise
 */
export function processCSV(csvString, readString) {
  return new Promise((resolve, reject) => {
    try {
      if (!csvString) {
        throw Error(`Invalid input param csvString : ${csvString}`);
      }

      if (typeof csvString !== "string") {
        throw Error(
          `Invalid input param csvString : ${csvString}. Expecting string.`
        );
      }

      readString(csvString, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results?.errors?.length > 0) {
            console.error(results?.errors[0]);
            throw Error(results?.errors[0]);
          }

          if (!results?.data) {
            throw Error(`results data undefined.`);
          }

          resolve(results?.data);
          return;
        },
      });
    } catch (e) {
      console.error(e?.message);
      reject(e);
      return;
    }
  });
}

export function getUserUnitsCollectionRef(db, userRef) {
  try {
    if (!db || !userRef) {
      throw Error(`invalid input params db: ${db}, userRef: ${userRef}.`);
    }

    return db
      ?.collection("careerPrep")
      .where("userRef", "==", userRef)
      .orderBy("ts", "asc");
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function getUserQuestionsRefs(db, userRef) {
  try {
    if (!db || !userRef) {
      throw Error(`invalid input params db: ${db}, userRef: ${userRef}.`);
    }

    return db
      ?.collection("careerPrep")
      .where("members", "array-contains", userRef)
      .orderBy("ts", "asc");
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function assignUnitToUser(
  db,
  unit,
  userRef,
  isLastUnitOfTheCourse,
  firebase
) {
  try {
    if (
      !db ||
      !userRef ||
      !unit?.name ||
      !unit?.reference ||
      isLastUnitOfTheCourse === undefined ||
      isLastUnitOfTheCourse === null ||
      typeof isLastUnitOfTheCourse !== "boolean" ||
      !firebase
    ) {
      throw Error(
        `invalid input params db: ${db}, unit: ${unit}, userRef: ${userRef}, isLastUnitOfTheCourse: ${isLastUnitOfTheCourse}, firebase: ${firebase}.`
      );
    }

    return db.collection("careerPrep").add({
      name: unit.name,
      show: true,
      source: unit.reference,
      ts: firebase.firestore.Timestamp.fromDate(new Date()),
      userRef: userRef,
      isLastUnitOfTheCourse,
    });
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

/**
 * Retrieves the course name from a given unit reference.
 * @param {DocumentReference} unitRef - The unit reference.
 * @returns {Promise<object>} courseName, courseID, courseType
 */

export async function getCourseDataFromUnitSource(unitRef) {
  try {
    if (!unitRef.exists) {
      throw Error("The unit document does not exist.");
    }

    const courseRef = unitRef?.ref?.parent?.parent;

    if (!courseRef) {
      throw Error("unitRef does not have a parent.");
    }

    const courseInformation = await courseRef?.get();

    if (!courseInformation.exists) {
      throw Error("The parent course document does not exist.");
    }
   
    const courseName = courseInformation.data()?.name;
    const courseID = courseInformation?.id;
    const courseType = courseInformation.data()?.courseType;
    const courseSubject = courseInformation.data()?.subject;

    return { courseName, courseID, courseType, courseSubject };
  } catch (error) {
    return Promise.reject(error);
  }
}

export const RANDOM_EVALUATING_RESPONSES = [
  "Assessing your response, just a moment",
  "Analyzing your reply, give me a second",
  "Reviewing your solution, hold tight",
  "Considering your input, be patient",
  "Examining your response, hang in there",
  "Scrutinizing your answer, a brief pause",
  "Appraising your reply, one moment please",
  "Judging your explanation, stand by",
  "Weighing your response, hold steady",
  "Assaying your input, a short delay",
  "Assessing your interpretation, stand by",
  "Deliberating on your answer, hold tight",
  "Pondering your solution, just a moment",
  "Reflecting on your response, one moment",
  "Contemplating your reply, be patient",
  "Inspecting your explanation, hold tight",
  "Evaluating your statement, hang in there",
  "Measuring your understanding, stand by",
  "Appraising your assessment, hold tight",
  "Scrutinizing your viewpoint, hold steady",
];

export const RANDOM_RESPONSES_FOR_MISSING_PROMPT = [
  "Excellent response!",
  "Well done!",
  "That's the right way to put it!",
  "Perfect reply!",
  "Spot on!",
  "You nailed it!",
  "Outstanding answer!",
  "Bravo!",
  "You're on point!",
  "Great job!",
  "Impressive response!",
  "Superb!",
  "Fantastic answer!",
  "You're absolutely right!",
  "Terrific!",
  "I couldn't have said it better myself!",
  "Kudos to you!",
  "A+ answer!",
  "Phenomenal!",
  "You're a genius!",
  "Hats off to you!",
  "You really know your stuff!",
  "Outstanding work!",
  "Brilliant!",
  "You're a star!",
];

export const RANDOM_UNIT_COMPLETE_RESPONSES = [
  "Keep going! Progress is your best friend.",
  "Every effort counts! You're getting there.",
  "Way to go! You're on the right path.",
  "Success is a journey, not a destination. You're making strides!",
  "Small victories lead to big triumphs. You're doing great!",
  "Bravo! Your determination is shining through.",
  "You're making waves of progress. Keep riding that momentum!",
  "Aim high and reach far. You're making it happen!",
  "The road to success is paved with persistence. You're paving the way!",
  "Each step forward is a step closer to your dreams. Keep stepping!",
  "You're writing your own success story. What a great chapter!",
  "You're turning your dreams into reality, one step at a time.",
  "You're in the winner's circle of progress. Keep running the race!",
  "With every effort, you're inching closer to greatness.",
  "Your hard work is paying off. Keep the momentum going!",
];

export const RANDOM_SKIP_RESPONSES = [
  "No problemo!",
  "Certainly!",
  "Absolutely!",
  "You got it!",
  "Of course!",
  "No sweat!",
];
export const RANDOM_CONTINUE_RESPONSES = [
  "Bravo!",
  "Good job!",
  "Outstanding!",
  "Well done!",
  "Great work!",
  "Keep it up!",
  "Kudos!",
];

function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function generateRandomResponse(responseArray, defaultValue) {
  try {
    if (!Array.isArray(responseArray)) {
      throw Error(`invalid responseArray. Expecting array.`);
    }

    if (!defaultValue) {
      throw Error(`invalid defaultValue. Expecting string.`);
    }

    return responseArray[randomNumber(0, responseArray?.length - 1)];
  } catch (e) {
    console.error(e);

    if (!defaultValue) {
      throw e;
    }
    return defaultValue;
  }
}

export function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

/**
 *
 * @param {Array} questions - questions within a unit
 * @returns Array of lessons
 */
export function convertUnitQuestionsToLessons(questions) {
  let MAX_QUESTIONS_PER_LESSON = 4;
  const MIN_QUESTIONS_PER_LESSON = 2;

  if (questions?.length <= MAX_QUESTIONS_PER_LESSON) {
    return [questions];
  }

  if (questions?.length > MAX_QUESTIONS_PER_LESSON) {
    let approxNumOfLessons = Math.round(
      questions?.length / MAX_QUESTIONS_PER_LESSON
    );

    const mod = questions?.length % MAX_QUESTIONS_PER_LESSON;

    if (mod !== 0 && mod < MIN_QUESTIONS_PER_LESSON) {
      console.log(`last lesson is gonna end up with less than min questions`);
      // TODO: Is this a good approach?
      MAX_QUESTIONS_PER_LESSON -= 1;
      approxNumOfLessons = Math.round(
        questions?.length / MAX_QUESTIONS_PER_LESSON
      );
    }

    let lessons = [];
    let startIndex = 0;
    let endIndex = MAX_QUESTIONS_PER_LESSON;

    for (let i = 0; i < approxNumOfLessons; i++) {
      if (!questions[startIndex] || !questions[endIndex]) {
        endIndex = questions?.length;
      }
      lessons.push(questions.slice(startIndex, endIndex));
      startIndex = endIndex;
      endIndex += MAX_QUESTIONS_PER_LESSON;
    }

    return lessons;
  }
}

export function limitWords(string, maxWords) {
  if (string && string?.length > 0) {
    let addEllipsis = false;
    if (string.split(" ").length >= maxWords) {
      addEllipsis = true;
    }
    const shortenedString = string?.split(" ").slice(0, maxWords).join(" ");
    if (addEllipsis) {
      return shortenedString + " ...";
    } else {
      return shortenedString;
    }
  } else {
    return string;
  }
}

/**
 * This function connects to CloudRun and disconnects itself after half a second
 * Hoping that this call to cloudRun upon launching the app would reduce the
 * CloudRun's cold start time
 */
export function dryRunCloudRun() {
  const socket = io.connect(
    "https://nestria-speech-to-text-vadqfrngpq-el.a.run.app"
  );
  console.log(`trying to connect`);
  socket.on("connect", () => {
    console.log("connected", socket.id);
    socket.emit("send_message", "handshake");
    setTimeout(() => {
      socket?.disconnect();
    }, 500);
  });

  socket.on("disconnect", () => {
    console.log("Disconnected", socket.id);
  });
}

export const signupMessages = [
  "Thanks for joining! Your journey is beginning. Relax while we set up your account.",
  "Exciting news! You're closer to our community. Final touches on your account are underway.",
  "Big moments! Your account is in creation. Hold on, you'll explore all features soon.",
  "Almost there! We're working behind the scenes to set up your account. Your wait will be worth it!",
  "Your enthusiasm is appreciated! A bit more, and you'll access our platform. Thanks for choosing us!",
  "Patience pays off! Account setup is in progress. Sit back and relax for a moment.",
  "Real anticipation! Your sign-up is in progress. Eager for you to experience all we offer.",
  "Welcome aboard! Your journey begins. We're crafting your account, so hang tight.",
  "Great moments ahead! Your account is being prepared. Thanks for your patience!",
  "Just a bit more! Your account setup is in progress. We appreciate your choice.",
  "Your journey starts now! We're finalizing your account setup. Relax while we work.",
  "Almost there! Your account is in the final stages. Your wait will be rewarded.",
  "Excitement builds! Your account setup is in progress. Soon, you'll explore our platform.",
  "Anticipation rises! Your sign-up is in progress. Get ready to experience our offerings.",
  "Thanks for choosing us! Your journey is underway. Relax while we set up your account.",
  "Appreciate your enthusiasm! A little longer, and you'll have full access. Thanks for choosing us!",
  "Patience rewarded! Your account setup is in progress. Sit back and relax for a moment.",
  "Anticipation is real! Your sign-up is underway. Eager for you to experience everything.",
  "Welcome! Your journey begins. We're crafting your account. Thanks for your patience!",
  "Exciting times! Your account is being prepared. Hold tight, and you'll explore all features soon.",
];

export const waitingForContentToLoadMessages = [
  "Fetching the good stuff...", 
  "Preparing awesomeness...", 
  "Almost there...", 
  "Your content is on the way...", 
  "Making magic happen...", 
  "Please hold tight...", 
  "Getting things ready...", 
  "Summoning data...", 
  "Just a moment...", 
  "Your request is processing...", 
  "Good things take time...", 
  "Loading the fun...", 
  "Powering up...", 
  "Bringing it to you...", 
  "Stay tuned...", 
  "Hold on, almost ready...", 
  "Working on it...", 
  "Fetching your awesomeness...", 
  "Stay with us...", 
  "The wait is almost over...",
];

export function isPWAInstalled() {
  return window?.matchMedia('(display-mode: standalone)')?.matches;
}

export function isEmpty(value) {
  return value === null || value === undefined || value === "" || value === void 0;
}